import React, { useEffect } from "react"

const Adsense = props => {
  const { path } = props

  //// https://www.ultra-noob.com/blog/2020/2020-12-07-Gatsby_js%E3%82%B5%E3%82%A4%E3%83%88%E3%81%A7Google%20AdSence%E3%82%92%E5%8B%95%E4%BD%9C%E3%81%95%E3%81%9B%E3%82%8B/
  useEffect(() => {
    if (window && window.adsbygoogle && process.env.NODE_ENV !== "development") {
      window.adsbygoogle.push({});
    }
  }, [path])

  return (
    <div className="adsense">
      <ins
          className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-7047115928698862"
          data-ad-slot="7038566834"
          data-ad-format="auto"
          data-full-width-responsive="true"
      />
    </div>
  )
}

export default Adsense
